<script lang="ts" setup>
import Heading from '@/components/Heading.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

const props = defineProps({
  isLinkFlow: Boolean,
  documentTitle: String,
  requestOwnerName: String,
  isLoading: Boolean,
})

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
}>()

const { t } = useI18n()

const info = computed<{ title: string; subtitle: string; positiveAction: string }>(() => {
  if (props.isLinkFlow) {
    return {
      title: t('viewer.tan.expired.link_flow.title'),
      subtitle: t('viewer.tan.expired.link_flow.text'),
      positiveAction: t('viewer.tan.expired.link_flow.cta'),
    }
  } else {
    return {
      title: t('viewer.tan.expired.tan_flow.title'),
      subtitle: t('viewer.tan.expired.tan_flow.text'),
      positiveAction: t('viewer.tan.expired.tan_flow.cta'),
    }
  }
})

const sendTan = () => {
  emit('action', 'retry')
}
</script>

<template>
  <overlay-base :transition="false">
    <v-container class="overlay__body fill-height">
      <v-row>
        <v-col cols="12" md="6" order="2" order-md="1">
          <heading :title="info.title" :subtitle="info.subtitle" />
          <p v-if="documentTitle">
            <span>{{ t('viewer.tan.document_title') }}</span>
            {{ ' ' }}
            <strong>{{ documentTitle }}</strong>
          </p>
          <p v-if="requestOwnerName">
            <span>{{ t('viewer.tan.invited_by_title') }}</span>
            {{ ' ' }}
            <strong class="text-truncate">{{ requestOwnerName }}</strong>
          </p>
          <v-btn block :loading="isLoading" color="primary" size="large" @click="sendTan">
            {{ info.positiveAction }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
          <responsive-image source="tan-expired" :width="560" :height="511" />
        </v-col>
      </v-row>
    </v-container>
  </overlay-base>
</template>
